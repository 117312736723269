<template>
  <div class="tooltip-container">
    <slot />
    <div class="tooltip">
      <span class="text">{{ text }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
  /* margin: 100px; */
}

.tooltip-container:hover .tooltip {
  opacity: 1;
  min-width: 220px;
  display: block;
  /* width: none;
  height: none; */
}

.tooltip {
  color: #ffffff;
  text-align: center;
  padding: 5px;
  border-radius: 2px;

  display: none;
  /* height: 0; */
  /* bottom: 100%; */
  /* left: 50%; */
  margin-left: -103px;

  opacity: 0;
  transition: opacity 1s;

  position: absolute;
  z-index: 1;

  background: #212020;
}

/* .text::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000000 transparent transparent transparent;
} */
</style>