<template>
  <form>
    <div
      class="inputs"
      :style="`min-height: ${minheight ?? '300px'}; ${
        !flex && !nooverflow ? 'overflow: auto;' : ''
      }`"
    >
      <div class="mb-1" v-for="(row, index) in formFields.rows" :key="index">
        <div class="d-flex flex-row align-items-end">
          <div
            :class="flex ? 'me-3' : 'col me-3'"
            v-for="(val, index) in row"
            :key="index"
          >
            <div
              class=""
              v-for="(value, propertyName) in val"
              :key="propertyName"
            >
              <hr v-if="val && val[propertyName].type == 'divider'" />
              <h3 v-else-if="val && val[propertyName].type == 'h3'">
                {{ val[propertyName].label }}
              </h3>
              <h4 v-else-if="val && val[propertyName].type == 'h4'">
                {{ val[propertyName].label }}
              </h4>
              <h5 v-else-if="val && val[propertyName].type == 'h5'">
                {{ val[propertyName].label }}
              </h5>
              <h6 v-else-if="val && val[propertyName].type == 'h6'">
                {{ val[propertyName].label }}
              </h6>
              <span
                v-else-if="val && val[propertyName]"
                class="row"
                :style="
                  val[propertyName].width
                    ? `width:${val[propertyName].width}`
                    : ''
                "
              >
                <label
                  v-if="
                    val &&
                    val[propertyName] &&
                    val[propertyName].type != 'hidden' &&
                    val[propertyName].type != 'checkbox'
                  "
                  :for="propertyName"
                  class="form-label"
                  :class="val[propertyName].align"
                  >{{ val[propertyName].label }}
                  <Tooltip
                    v-if="val[propertyName].description"
                    :text="val[propertyName].description"
                  >
                    <div class="small">
                      <i class="bi bi-info-circle"></i>
                    </div> </Tooltip
                ></label>
                <div class="">
                  <span v-if="val[propertyName].type == 'textarray'">
                    <textarea
                      class="form-control"
                      :id="propertyName"
                      :rows="val[propertyName].rows ?? 3"
                      v-model="formData[propertyName]"
                      :disabled="val[propertyName].disabled == true"
                      @blur="
                        $emit('changed', formData[propertyName], propertyName)
                      "
                    ></textarea>
                  </span>
                  <span v-else-if="val[propertyName].type == 'dropdown'">
                    <DropDown
                      :url="val[propertyName].url"
                      :label="val[propertyName].label"
                      :value="formData[propertyName]"
                      :list="val[propertyName].list"
                      :empty="val[propertyName].empty"
                      :labelfield="val[propertyName].labelField"
                      :dropdownHeight="val[propertyName].dropdownHeight"
                      :multiple="val[propertyName].multiple"
                      :disabled="val[propertyName].disabled == true"
                      :clearable="val[propertyName].clearable ?? false"
                      @selected="
                        (e) => {
                          ddChanged(e, propertyName);
                          $emit('changed', e, propertyName);
                        }
                      "
                    />
                  </span>
                  <span v-else-if="val[propertyName].type == 'checkbox'">
                    <input
                      :type="val[propertyName].type"
                      class="form-check-input me-2"
                      :id="propertyName"
                      v-model="formData[propertyName]"
                      :disabled="val[propertyName].disabled == true"
                      @blur="
                        $emit('changed', formData[propertyName], propertyName)
                      "
                    />
                    <label :for="propertyName" class="form-label">{{
                      val[propertyName].label
                    }}</label>
                  </span>
                  <span v-else-if="val[propertyName].type == 'date'">
                    <datepicker
                      v-model="formData[propertyName]" 
                      format="dd.MM.yyyy" 
                      monday-first="true" 
                      :clear-button="val[propertyName].disabled != true" 
                      :disabled="val[propertyName].disabled == true"
                      :open-date="formData[propertyName]"
                      @input="
                        dateChanged(propertyName, formData[propertyName]);
                      "
                      ></datepicker>
                  </span>
                  <span v-else-if="val[propertyName].type == 'button'">
                    <button
                      :class="`btn ${
                        val[propertyName].btnColor ?? 'btn-success'
                      } bi ${val[propertyName].biIcon}`"
                      type="button"
                      @click="$emit(val[propertyName].emit, formData)"
                    >
                      {{ val[propertyName].text }}
                    </button>
                  </span>
                  <span v-else-if="val[propertyName].type == 'component'">
                    <component
                      :is="val[propertyName].component"
                      v-bind="val[propertyName].properties"
                      @save="val[propertyName].save"
                      @cancel="val[propertyName].cancel"
                      @changed="
                        $emit(
                          'changed',
                          val[propertyName].properties.data,
                          propertyName
                        )
                      "
                    ></component>
                  </span>
                  <span v-else>
                    <input
                      :key="val[propertyName].key ?? propertyName"
                      :type="val[propertyName].type"
                      class="form-control"
                      :id="propertyName"
                      v-model="formData[propertyName]"
                      :disabled="val[propertyName].disabled == true"
                      @blur="
                        $emit('changed', formData[propertyName], propertyName)
                      "
                    />
                  </span>
                  <figcaption
                    v-if="
                      val &&
                      val[propertyName] &&
                      val[propertyName].blockquote &&
                      val[propertyName].type != 'hidden' &&
                      val[propertyName].type != 'checkbox'
                    "
                    class="blockquote-footer text-danger pt-3 mb-0"
                  >
                    {{ val[propertyName].blockquote }}
                  </figcaption>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-1">
      <div class="row">
        <div class="col align-self-end">
          <div class="actions" v-if="readonly != true">
            <button
              class="btn btn-success bi bi-check-lg"
              type="button"
              @click="$emit('save', formData)"
            >
              Sačuvaj
            </button>
            <button
              v-for="(val, index) in extraButtons"
              :key="index"
              class="btn btn-success bi bi-check-lg"
              type="button"
              @click="$emit(val['emit'], formData)"
            >
              {{ val["text"] }}
            </button>
            <button
              class="btn btn-danger bi bi-x-lg"
              type="button"
              @click="$emit('cancel')"
            >
              Otkaži
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
// import dateUtils from "../../utils/date-common";
import DropDown from "./DropDown.vue";
import Tooltip from "./Tooltip";
import Datepicker from 'vuejs3-datepicker';
export default {
  name: "data-form2",
  props: [
    "data",
    "formFields",
    "extraButtons",
    "readonly",
    "flex",
    "minheight",
    "nooverflow",
  ],
  components: {
    DropDown,
    Tooltip,
    Datepicker,
  },
  data() {
    return {
      formData: this.data,
      tempVal: "3",
    };
  },
  computed: {
    createdDate() {
      return this.data && this.data["createdAt"]
        ? this.data["createdAt"].substring(0, 10)
        : new Date().toISOString().substring(0, 10);
    },
  },
  methods: {
    ddChanged(event, val) {
      if (event && event.target) {
        this.formData[val] = event.target.value;
      } else {
        if (event) {
          this.formData[val] = event;
        } else {
          // console.log({ sel: this.formData[val] });
          this.formData[val] = null;
        }
      }
    },
    dateChanged(event, val) {
      val.setHours(12); /// to hack time zone changes - wil work with our time zone
      this.formData[event] = val.toISOString().substring(0, 10);
      this.$emit('changed', val.toISOString().substring(0, 10), event)
    },
  },
  // watch: {
  //   formData: {
  //     handler: function (newVal, oldVal) {
  //       console.log({ oldVal, newVal });
  //     },
  //     deep: true,
  //   },
  //   "itemData.price"(newVal) {
  //     console.log({ newVal });
  //   },
  // },
};
</script>

<style scoped>
.actions {
  float: right;
}

.actions > .btn,
.actions > .btn-group {
  margin: 0.25rem 0.125rem;
}

.inputs {
  min-height: 300px;
  max-height: calc(100vh - 210px);
  /* overflow: auto; */
}
input[type="number"] {
  text-align: right;
}
</style>